<template>
	<div class="shop-total">
		<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/dyt_pc_shop_bg.png?v=1" class="bg" />
		<div class="content" v-loading="loading">
			<div class="tab">
				<img v-if="current == 0"
					:src="'https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/dyt_pc_shop_btn1.png?v='+Math.random()"
					alt="">
				<img v-if="current == 1"
					:src="'https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/dyt_pc_shop_btn2.png?v='+Math.random()"
					alt="">
				<span class="tabs">
					<label for="" @click="tabChange(0)"></label>
					<label for="" @click="tabChange(1)"></label>
				</span>

			</div>
			<div v-for="(item, index) in list" :key="item.id" class="item">
				<div v-if="index != list.length - 1" class="line"></div>
				<div class="itemLon-one">
					<img class="icon1" src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/dytmini/dianpu.png" alt="">
					<label for="" :class="current !== 1?'itemLon-ones':'itemLon-oneb'">
						{{item.smallarea}}
						<font>|</font>
						{{item.name}}
					</label>
				</div>
				<div class="lon" v-if="current !== 1"></div>
				<div class="itemLon" v-if="current !== 1">
					<img class="icon1"
						src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/dytmini/dyt_shop_loc_icon.png" alt="">
					<label for="">{{ item.address }}</label>
				</div>
				<div class="lon"></div>
				<div class="itemLon-show">
					<img class="banner"
						:src="item.imgUrl != null?item.imgUrl:'https://jiaqi.obs.cn-north-1.myhuaweicloud.com/dytmini/dyt_shop_head.png'" />
					<!-- <label for="">销售：姚石</label> -->
				</div>
				<div class="lon"></div>
				<div class="itemLon-phoe">
					<label for=""><img class="icon2"
							src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/dytmini/dyt_shop_orange_phone.png" /></label>
					<label for="">
						<!-- <font>{{ item.phone }}</font> -->
						<font>{{ item.phone }}</font>
					</label>
				</div>
				<!-- <img class="banner" src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/dytmini/dyt_shop_head.png" />
				<div class="info">
					<section class="infot">
						<div class="name">{{ item.name }}</div>
						<div class="detail-loc">
							<img class="icon"
								src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/dytmini/dyt_shop_loc_icon.png" />
							<div class="location">{{ item.smallarea}}</div>
						</div>
						<div class="detail-phone">
							<img class="icon2"
								src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/dytmini/dyt_shop_orange_phone.png" />
							<div class="phone">{{ item.phone }}</div>
						</div>
					</section>
					<section class="adres" v-if="current !== 1">门店地址：{{ item.address }}</section>
				</div> -->
			</div>
		</div>
		<div class="bottom">
			<img @click="clickLastPage" class="last-btn"
				src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/dyt_pc_last_page.png" />
			<img @click="clickNextPage" class="next-btn"
				src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/dyt_pc_next_page.png" />
		</div>
	</div>
</template>

<script>
	import {
		getShopList,
		getShopLists
	} from "@/api/index";
	export default {
		data() {
			return {
				loading: true,
				page: 1,
				list: [],
				current: 0
			};
		},
		created() {
			this.connectLists();
		},
		methods: {
			tabChange(e) {
				this.current = e
				this.page = 1
				this.list = []
				if (e == 1) {
					this.connectList();
				} else {
					this.connectLists();
				}
			},
			clickLastPage() {
				if (this.page > 1) {
					this.page -= 1;
				}
				if (this.current == 1) {
					this.connectList();
				} else {
					this.connectLists();
				}
			},
			clickNextPage() {
				if (this.list.length % 10 != 0) return;
				this.page += 1;
				if (this.current == 1) {
					this.connectList();
				} else {
					this.connectLists();
				}
			},
			connectList() {
				this.loading = true;
				var orgID = this.$app == "dy" ? 461 : 559
				getShopList(this.page, orgID).then((res) => {
					this.list = [];
					if (res.objects != null) {
						// res.objects.forEach((element) => {
						// 	let ary = element.areaInformationName.split("-");
						// 	element.areaInformationName = ary[ary.length - 1];
						// });
						this.list = res.objects;
					}
					this.loading = false;
				});
			},
			connectLists() {
				this.loading = true;
				var orgID = this.$app == "dy" ? 461 : 559
				getShopLists(this.page, orgID).then((res) => {
					this.list = [];
					if (res.objects != null) {
						// res.objects.forEach((element) => {
						// 	let ary = element.areaInformationName.split("-");
						// 	element.areaInformationName = ary[ary.length - 1];
						// });
						this.list = res.objects;
					}
					this.loading = false;
				});
			},
		},
	};
</script>

<style lang="less">
	.shop-total {
		display: flex;
		align-items: center;
		flex-direction: column;
		position: relative;
		width: 100%;

		.bottom {
			display: flex;
			margin-top: 53px;
			position: absolute;
			top: 1370px;

			.last-btn {
				width: 179px;
				height: 58px;
				margin-right: 25px;
			}

			.next-btn {
				margin-left: 25px;
				width: 179px;
				height: 58px;
			}
		}

		.bg {
			width: 100%;
		}

		.content {
			display: flex;
			flex-direction: column;
			position: absolute;
			top: 281px;
			width: 1200px;
			height: 1100px;
			background: #ffffff;
			align-items: center;
			border-radius: 30px;

			.tab {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				// padding: 20px;
				// box-sizing: border-box;
			}

			.tabs {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
			}

			.tabs label {
				flex: 1;
				height: 80px;
			}

			.tab img {
				width: 100%;
			}

			.item {
				width: calc(100% - 49px * 2);
				display: flex;
				position: relative;
				height: 110px;
				align-items: center;

				.line {
					width: 100%;
					height: 1px;
					position: absolute;
					top: 109px;
					background-color: #eee;
				}

				.banner {
					width: 64px;
					height: 64px;
				}

				.info {
					display: flex;
					flex-direction: column;
					margin-left: 30px;
					width: 100%;
					// align-items: center;
					justify-content: center;
					position: relative;

					.infot {
						width: 100%;
						display: flex;
					}

					.adres {
						padding-top: 4px;
						color: #999;
						font-size: 16px;
					}

					.detail-loc {
						display: flex;
						align-items: center;
						position: absolute;
						left: 350px;
					}

					.detail-phone {
						display: flex;
						align-items: center;
						width: 197px;
						height: 41px;
						background: #fff3ef;
						border-top-left-radius: 30px;
						border-bottom-left-radius: 30px;
						position: absolute;
						right: -49px;
					}

					.name {
						width: 320px;
						font-size: 22px;
						font-weight: 500;
						color: #000000;
					}

					.icon {
						width: 24px;
						height: 24px;
					}


					.location {
						font-size: 18px;
						font-weight: 400;
						margin-left: 6px;
						color: #686868;
					}

					.phone {
						font-size: 18px;
						font-weight: 400;
						margin-left: 6px;
						color: #686868;
					}
				}
			}
		}
	}

	.icon1 {
		width: 24px;
		height: 24px;
		margin-right: 9px;
	}

	.itemLon {
		display: flex;
		align-items: center;
	}

	.itemLon label {
		font-size: 16px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #686868;
		line-height: 24px;
		width: 260px;
	}

	.itemLon-one {
		display: flex;
		align-items: center;
	}

	.itemLon-ones {
		width: 218px;
		font-size: 20px;
		font-family: Source Han Sans CN;
		font-weight: 500;
		color: #333333;
	}

	.itemLon-oneb {
		width: 500px;
		font-size: 20px;
		font-family: Source Han Sans CN;
		font-weight: 500;
		color: #333333;
	}

	.itemLon-one font {
		font-size: 18px;
		padding: 0 6px;
	}

	.lon {
		width: 1px;
		height: 40px;
		background-color: #E8E8E8;
		margin: 0 29px 0 29px;
	}

	.itemLon-show {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 0 30px 0 20px;
	}

	.itemLon-show img {
		width: 64px;
		height: 64px;
	}

	.itemLon-show label {
		width: 118px;
		height: 25px;
		background: #FFF3EF;
		border: 1px solid #FFDDC2;
		border-radius: 13px;
		font-size: 16px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #333333;
		line-height: 24px;
		text-align: center;
		margin-top: -10px
	}

	.itemLon-phoe {
		width: 205px;
		height: 67px;
		background: #FFF3EF;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		position: absolute;
		right: -49px;
		display: flex;
		align-items: center;
		padding-left: 6px;
		box-sizing: border-box;
	}

	.itemLon-phoe label:first-child {
		width: 56px;
		height: 56px;
		background: #FFFDFC;
		border-radius: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.itemLon-phoe label:last-child {
		font-size: 18px;
		color: #F59575;
		margin-left: 7px;
		display: flex;
		flex-direction: column;
	}

	.itemLon-phoe label:last-child font:first-child {
		color: #333333;
		;
	}

	.icon2 {
		width: 24px;
		height: 24px;
	}
</style>
